import {MutableRefObject, createContext} from 'react';

export interface Asset {
  id?: string | number;
  attributes?: {
    title?: string | null;
    uid?: string | null;
    transcript?: {
      data?: {
        attributes?: {
          transcriberUid?: string | null;
          isReady?: boolean | null;
        } | null;
      } | null;
    } | null;
  } | null;
}

export type TetraBotSidebarAPI = {
  open: () => void;
  setActiveTab: (key: string) => void;
};

interface TetraBotContextValue {
  apiRef?: MutableRefObject<TetraBotSidebarAPI | null>;
  newThreadAssets: Asset[];
  setNewThreadAssets: (assets: Asset[]) => void;
}

const TetraBotContext = createContext<TetraBotContextValue>({
  newThreadAssets: [],
  setNewThreadAssets: () => {},
});

export default TetraBotContext;
