import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {MenuProps} from './Menu.types';

const Menu = (props: MenuProps) => {
  const {
    anchorOrigin = {horizontal: 'right', vertical: 'bottom'},
    children,
    id,
    items,
    onClose,
    transformOrigin = {horizontal: 'right', vertical: 'top'},
    ...others
  } = props;

  const getMenuItem = ({
    color,
    disabled,
    icon,
    index,
    itemChildren,
    label,
    onClick,
  }) => {
    return (
      <MenuItem
        key={index}
        className={disabled ? 'disabled' : ''}
        sx={{
          gap: 1,
          padding: '6px 6px',
          fontSize: '14px',
          borderRadius: '4px',
          transition: 'background 75ms',
          boxSizing: 'border-box',
          '&.disabled': {
            pointerEvents: 'none',
            '&:hover, &:focus': {
              background: 'inherit',
            },
          },
          '&:hover, &:focus': {
            background: 'rgba(0, 0, 0, 0.04)',
          },
          '&:not(:last-child)': {
            marginBottom: '2px',
          },
        }}
        onClick={(event) => {
          onClose?.(event, 'escapeKeyDown');
          onClick(event);
        }}
      >
        {itemChildren ?? (
          <>
            {icon && (
              <ListItemIcon
                sx={{
                  minWidth: '0 !important',
                  fontSize: '18px',
                  color: color ?? 'inherit',
                }}
              >
                {icon}
              </ListItemIcon>
            )}
            <ListItemText
              primaryTypographyProps={{
                variant: 'body2',
                color: color ?? 'inherit',
              }}
            >
              {label}
            </ListItemText>
          </>
        )}
      </MenuItem>
    );
  };

  return (
    <MuiMenu
      key={id}
      MenuListProps={{
        'aria-labelledby': `${id}Button`,
      }}
      anchorOrigin={anchorOrigin}
      elevation={1}
      sx={{
        '& .MuiBackdrop-root': {
          opacity: '0 !important',
        },
        '& .MuiList-root': {
          padding: 0,
        },
        '& .MuiPaper-root': {
          minWidth: '200px',
          padding: 1,
          border: '1px solid rgba(0, 0, 0, .1)',
          borderRadius: '6px',
          boxShadow:
            '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
        },
      }}
      transformOrigin={transformOrigin}
      transitionDuration={150}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onClose={onClose}
      {...others}
    >
      {items
        ? items.map((item, index) => {
            const {
              color,
              disabled,
              divider,
              icon,
              isHidden,
              itemChildren,
              label,
              onClick,
            } = item;

            return (
              !isHidden &&
              (divider ? (
                <Divider key={index} />
              ) : (
                getMenuItem({
                  color,
                  disabled,
                  label,
                  onClick,
                  itemChildren,
                  icon,
                  index,
                })
              ))
            );
          })
        : children}
    </MuiMenu>
  );
};

export default Menu;
