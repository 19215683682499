'use client';

import {Tab, Tabs} from '@mui/material';
import Link from 'next/link';
import {usePathname} from 'next/navigation';

import usePermission from '@src/hooks/usePermission';
import useProjectLayoutData from '@src/hooks/useProjectLayoutData';
import useSessionsProject from '@src/hooks/useSessionsProject';
import {useSearchParams} from '@src/hooks/useSearchParams';

type NavigationHeader =
  | 'home'
  | 'projects'
  | 'insights-hub'
  | 'templates'
  | 'sessions';

const TopNavigationContext = ({children}) => {
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const projectUid = searchParams.get('projectUid');
  const {projectData} = useProjectLayoutData(projectUid);

  const isTemplate = searchParams.get('isTemplate')
    ? Boolean(searchParams.get('isTemplate'))
    : projectData?.attributes?.isTemplate;

  const isSession = projectData?.attributes?.isSessionsProject;

  let activeTab: NavigationHeader | false = false;
  if (pathName == '/') {
    activeTab = 'home';
  } else if (pathName.startsWith('/templates') || (projectUid && isTemplate)) {
    activeTab = 'templates';
  } else if (projectUid && isSession) {
    activeTab = 'sessions';
  } else if (pathName.startsWith('/insights-hub')) {
    activeTab = 'insights-hub';
  } else if (pathName.startsWith('/project')) {
    activeTab = 'projects';
  }

  return <Tabs value={activeTab}>{children}</Tabs>;
};

const TopNavigation = () => {
  const canManageTemplates = usePermission('manage', 'templates');
  const {goToSessionProject} = useSessionsProject();

  return (
    <TopNavigationContext>
      <Tab component={Link} href="/" label="Home" value="home" />
      <Tab component={Link} href="/projects" label="Projects" value="projects" />
      <Tab label="Sessions" value="sessions" onClick={goToSessionProject} />
      <Tab
        component={Link}
        href="/insights-hub"
        label="Insights Hub"
        value="insights-hub"
      />
      {canManageTemplates && (
        <Tab
          component={Link}
          href="/templates"
          label="Templates"
          value="templates"
        />
      )}
    </TopNavigationContext>
  );
};

export default TopNavigation;
