'use client';

import {Button} from '@mui/material';
import {ChatTeardropText} from '@phosphor-icons/react';
import useUser from '@src/hooks/useUser';
import {useEffect} from 'react';
import {useIntercom} from 'react-use-intercom';

export default function SupportButton() {
  const intercom = useIntercom();
  const {user} = useUser();

  useEffect(() => {
    if (user.sub) {
      intercom.boot({
        hideDefaultLauncher: true,
        email: user?.email,
        userId: user?.sub,
        name: user?.name,
        company: {
          companyId: user?.org_id as string,
          name: user?.org_name as string,
        },
      });
    }
  }, [intercom, user]);

  return (
    <>
      <Button
        startIcon={<ChatTeardropText />}
        variant="text"
        onClick={() => {
          return intercom.show();
        }}
      >
        Support
      </Button>
    </>
  );
}
