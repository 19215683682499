'use client';

import {CSSProperties, useState} from 'react';

import {
  AutocompleteGroupedOption,
  Box,
  FilledInput,
  InputAdornment,
  Typography,
  useAutocomplete,
} from '@mui/material';
import {Folder, MagnifyingGlass, Pen} from '@phosphor-icons/react';

import {Hit} from 'meilisearch';

import {usePathname, useRouter} from 'next/navigation';
import useSWR from 'swr';

import Tag from '@components/core/display/Tag';
import {TagColor} from '@components/core/display/Tag/Tag.types';

import ClearButton from '@components/universal-search/clear-button';
import Group from '@components/universal-search/group';
import GroupTitle from '@components/universal-search/group-title';
import Item from '@components/universal-search/item';
import ItemContent from '@components/universal-search/item-content';
import ItemLabel from '@components/universal-search/item-label';
import Items from '@components/universal-search/items';
import SearchResults from '@components/universal-search/search-results';
import {useCMSClient} from '@src/hooks/useCMSClient';
import type {
  AssetSearchResult,
  AttachmentSearchResult,
  InsightSearchResult,
  ProjectPlanBlockSearchResult,
  ProjectSearchResult,
  SnippetSearchResult,
  TagSearchResult,
} from '@tetra-next/universal-search-types';

type AutocompleteOption =
  | ProjectAutocompleteOption
  | AssetAutocompleteOption
  | InsightAutocompleteOption
  | SnippetAutocompleteOption
  | TagAutocompleteOption
  | ProjectPlanBlockAutocompleteOption
  | AttachmentAutocompleteOption;

type ProjectAutocompleteOption = Hit<
  Pick<ProjectSearchResult, 'id' | 'uid' | 'name'>
> & {type: 'project'};

type AssetAutocompleteOption = Hit<
  Pick<
    AssetSearchResult,
    | 'id'
    | 'uid'
    | 'title'
    | 'project'
    | 'note'
    | 'transcript'
    | 'isBetweenChunks'
    | 'startTimeChunk'
  >
> & {type: 'asset'};

type InsightAutocompleteOption = Hit<
  Pick<
    InsightSearchResult,
    'id' | 'uid' | 'project' | 'title' | 'note' | 'isBetweenChunks'
  >
> & {type: 'insight'};

type SnippetAutocompleteOption = Hit<
  Pick<
    SnippetSearchResult,
    'id' | 'uid' | 'project' | 'text' | 'note' | 'isBetweenChunks'
  >
> & {type: 'snippet'};

type TagAutocompleteOption = Hit<
  Pick<
    TagSearchResult,
    | 'id'
    | 'uid'
    | 'project'
    | 'content'
    | 'color'
    | 'description'
    | 'isBetweenChunks'
  >
> & {type: 'tag'};

type ProjectPlanBlockAutocompleteOption = Hit<
  Pick<
    ProjectPlanBlockSearchResult,
    'id' | 'uid' | 'project' | 'heading' | 'content' | 'isBetweenChunks'
  >
> & {
  type: 'projectPlanBlock';
};

type AttachmentAutocompleteOption = Hit<
  Pick<
    AttachmentSearchResult,
    'id' | 'project' | 'attachmentId' | 'name' | 'assetUid' | 'insightUid'
  >
> & {
  type: 'attachment';
};

function hasMatches(text: string | undefined) {
  return text?.includes('<em>');
}

function getMatchExcerpt({
  isBetweenChunks,
  text,
}: {
  text: string;
  isBetweenChunks: boolean;
}): string {
  const matchStartIndex = text.indexOf('<em>');
  const matchEndIndex = text.lastIndexOf('</em>');

  const distance = 100;
  const excerptStartIndex = Math.max(0, matchStartIndex - distance);
  const excerptEndIndex = Math.min(text.length - 1, matchEndIndex + distance);

  const prefix = excerptStartIndex > 0 || isBetweenChunks ? '…' : '';
  const suffix = excerptEndIndex < text.length - 1 || isBetweenChunks ? '…' : '';

  return `${prefix}${text.slice(excerptStartIndex, excerptEndIndex)}${suffix}`;
}

export default function UniversalSearch() {
  const pathName = usePathname();
  const isSearchPage = pathName === '/search/';

  const [query, setQuery] = useState('');
  const CMSClient = useCMSClient();
  const router = useRouter();

  const searchQuery = useSWR(
    query && !isSearchPage && ['search-widget', query],
    async () => {
      const data = await CMSClient.search({
        input: {
          query,
          categories: [
            'projects',
            'interviews',
            'insights',
            'snippets',
            'tags',
            'projectPlanBlocks',
            'attachments',
          ],
        },
      });

      return data.search;
    },
    {
      keepPreviousData: true,
    }
  );

  const options: any[] = [];

  for (const project of searchQuery.data?.projects.data ?? []) {
    options.push({
      ...project,
      type: 'project',
    });
  }

  for (const asset of searchQuery.data?.assets.data ?? []) {
    options.push({
      ...asset,
      type: 'asset',
    });
  }

  for (const insight of searchQuery.data?.insights.data ?? []) {
    options.push({
      ...insight,
      type: 'insight',
    });
  }

  for (const tag of searchQuery.data?.tags.data ?? []) {
    options.push({
      ...tag,
      type: 'tag',
    });
  }

  for (const snippet of searchQuery.data?.snippets.data ?? []) {
    options.push({
      ...snippet,
      type: 'snippet',
    });
  }

  for (const projectPlanBlock of searchQuery.data?.projectPlanBlocks.data ?? []) {
    options.push({
      ...projectPlanBlock,
      type: 'projectPlanBlock',
    });
  }

  for (const attachment of searchQuery.data?.attachments.data ?? []) {
    options.push({
      ...attachment,
      type: 'attachment',
    });
  }

  const autocomplete = useAutocomplete({
    id: 'universal-search',
    freeSolo: true,
    inputValue: query,
    options,
    filterOptions: (options) => {
      return options;
    },
    getOptionLabel: (option) => {
      return typeof option === 'string' ? option : option.id;
    },
    groupBy: (option) => {
      return option.type;
    },
    onInputChange(_event, value, reason) {
      if (reason === 'reset') {
        return;
      }

      setQuery(value);
    },
    onChange(_event, value, reason) {
      if (reason === 'createOption' && typeof value === 'string') {
        const searchParams = new URLSearchParams();
        searchParams.set('q', value);

        router.push(`/search?${searchParams}`);
        return;
      }

      if (typeof value === 'string') {
        setQuery(value);
        return;
      }

      if (!value) {
        setQuery('');
        return;
      }

      if (value.type === 'project') {
        router.push(`/project?projectUid=${value.uid}`);
      }

      if (value.type === 'asset') {
        router.push(
          `/project/asset?projectUid=${value.project.uid}&assetUid=${value.uid}${
            value.startTimeChunk ? '?startTime=' + value.startTimeChunk : ''
          }`
        );
      }

      if (value.type === 'insight') {
        router.push(
          `/project/insight?projectUid=${value.project.uid}&insightUid=${value.uid}`
        );
      }

      if (value.type === 'snippet') {
        router.push(
          `/project/snippets?projectUid=${value.project.uid}&selectedSnippet=${value.uid}`
        );
      }

      if (value.type === 'tag') {
        router.push(
          `/project/tag?projectUid=${value.project.uid}&tagUid=${value.uid}`
        );
      }

      if (value.type === 'projectPlanBlock') {
        router.push(
          `/project/material?projectUid=${value.project.uid}&materialUid=${value.uid}`
        );
      }

      if (value.type === 'attachment') {
        if (value.assetUid) {
          router.push(
            `/project/asset?projectUid=${value.project.uid}&assetUid=${value.assetUid}#attachment-${value.attachmentId}`
          );

          return;
        }

        if (value.insightUid) {
          router.push(
            `/project/insight?projectUid=${value.project.uid}&insightUid=${value.insightUid}#attachment-${value.attachmentId}`
          );

          return;
        }

        router.push(
          `/project/attachments?projectUid=${value.project.uid}#attachment-${value.attachmentId}`
        );
      }
    },
  });

  if (isSearchPage) {
    return null;
  }

  return (
    <Box
      style={
        {
          '--input-width': '256px',
          '--popover-width': '600px',
        } as CSSProperties
      }
    >
      <Box
        {...autocomplete.getRootProps()}
        sx={{
          width: 'var(--input-width)',
        }}
      >
        <FilledInput
          endAdornment={
            <InputAdornment position="end">
              <ClearButton
                isVisible={autocomplete.inputValue.length > 0}
                {...autocomplete.getClearProps()}
              />
            </InputAdornment>
          }
          inputProps={autocomplete.getInputProps()}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <MagnifyingGlass />
            </InputAdornment>
          }
          type="search"
        />
      </Box>

      <SearchResults
        isOpen={autocomplete.popupOpen && autocomplete.groupedOptions.length > 0}
        {...autocomplete.getListboxProps()}
      >
        {(
          autocomplete.groupedOptions as Array<
            AutocompleteGroupedOption<AutocompleteOption>
          >
        ).map((group) => {
          return (
            <Group key={group.key}>
              <GroupTitle>
                {group.group === 'project' && 'Projects'}
                {group.group === 'asset' && 'Assets'}
                {group.group === 'insight' && 'Insights'}
                {group.group === 'snippet' && 'Snippets'}
                {group.group === 'tag' && 'Tags'}
                {group.group === 'projectPlanBlock' && 'Materials'}
                {group.group === 'attachment' && 'Supporting files'}
              </GroupTitle>

              <Items>
                {group.options.map((option, index) => {
                  return (
                    <Item
                      {...autocomplete.getOptionProps({
                        option,
                        index: group.index + index,
                      })}
                      key={option.id!}
                    >
                      {option.type === 'project' && (
                        <ItemLabel
                          dangerouslySetInnerHTML={{
                            __html: option._formatted!.name!,
                          }}
                        />
                      )}

                      {option.type === 'asset' && (
                        <>
                          <ItemLabel
                            dangerouslySetInnerHTML={{
                              __html: option._formatted!.title!,
                            }}
                          />

                          {hasMatches(option._formatted?.note) && (
                            <ItemContent
                              dangerouslySetInnerHTML={{
                                __html: getMatchExcerpt({
                                  text: option._formatted!.note!,
                                  isBetweenChunks: option.isBetweenChunks,
                                }),
                              }}
                            />
                          )}

                          {hasMatches(option._formatted?.transcript) && (
                            <ItemContent
                              dangerouslySetInnerHTML={{
                                __html: getMatchExcerpt({
                                  text: option._formatted!.transcript!,
                                  isBetweenChunks: option.isBetweenChunks,
                                }),
                              }}
                            />
                          )}
                        </>
                      )}

                      {option.type === 'insight' && (
                        <>
                          <ItemLabel
                            dangerouslySetInnerHTML={{
                              __html: option._formatted!.title!,
                            }}
                          />

                          {hasMatches(option._formatted?.note) && (
                            <ItemContent
                              dangerouslySetInnerHTML={{
                                __html: getMatchExcerpt({
                                  text: option._formatted!.note!,
                                  isBetweenChunks: option.isBetweenChunks,
                                }),
                              }}
                            />
                          )}
                        </>
                      )}

                      {option.type === 'snippet' && (
                        <>
                          {option._formatted?.note && (
                            <ItemLabel
                              dangerouslySetInnerHTML={{
                                __html: option._formatted!.note,
                              }}
                              icon={<Pen />}
                            />
                          )}

                          {hasMatches(option._formatted?.text) && (
                            <ItemContent
                              dangerouslySetInnerHTML={{
                                __html: getMatchExcerpt({
                                  text: option._formatted!.text!,
                                  isBetweenChunks: option.isBetweenChunks,
                                }),
                              }}
                            />
                          )}
                        </>
                      )}

                      {option.type === 'tag' && (
                        <>
                          <Tag color={option.color as TagColor}>
                            {option.content}
                          </Tag>

                          {hasMatches(option._formatted?.description) && (
                            <ItemContent
                              dangerouslySetInnerHTML={{
                                __html: getMatchExcerpt({
                                  text: option._formatted!.description!,
                                  isBetweenChunks: option.isBetweenChunks,
                                }),
                              }}
                            />
                          )}
                        </>
                      )}

                      {option.type === 'projectPlanBlock' && (
                        <>
                          <ItemLabel
                            dangerouslySetInnerHTML={{
                              __html: option._formatted!.heading!,
                            }}
                          />

                          {hasMatches(option._formatted?.content) && (
                            <ItemContent
                              dangerouslySetInnerHTML={{
                                __html: getMatchExcerpt({
                                  text: option._formatted!.content!,
                                  isBetweenChunks: option.isBetweenChunks,
                                }),
                              }}
                            />
                          )}
                        </>
                      )}

                      {option.type === 'attachment' && (
                        <ItemLabel
                          dangerouslySetInnerHTML={{
                            __html: option._formatted!.name!,
                          }}
                        />
                      )}

                      {option.type !== 'project' && (
                        <Box
                          sx={(theme) => {
                            return {
                              display: 'flex',
                              gap: 0.5,
                              alignItems: 'center',
                              mt: 0.5,
                              color: theme.colors.gray['600'],
                            };
                          }}
                        >
                          <Folder />
                          <Typography variant="body3">
                            {option.project.name}
                          </Typography>
                        </Box>
                      )}
                    </Item>
                  );
                })}
              </Items>
            </Group>
          );
        })}
      </SearchResults>
    </Box>
  );
}
