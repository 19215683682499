'use client';

import {FC, ReactNode} from 'react';

import {LiveblocksProvider, RoomProvider} from '@liveblocks/react';

import useEntityServiceClient from '@src/hooks/useEntityServiceClient';
import {useSubdomain} from '@src/hooks/useSubdomain';
import useUser from '@src/hooks/useUser';

import {
  Presence,
  RoomEvent,
  Storage,
  ThreadMeta,
  UserMeta,
} from '@tetra-next/liveblocks-types';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Liveblocks {
    Presence: Presence;
    Storage: Storage;
    UserMeta: UserMeta;
    RoomEvent: RoomEvent;
    ThreadMetadata: ThreadMeta;
    ActivitiesData: {
      $alert: {
        severity: 'info' | 'success' | 'error';
        title: string;
        message: string;
      };
    };
  }
}

interface LiveblocksProviderProps {
  children: ReactNode;
}

const LiveblocksRealtimeProvider: FC<LiveblocksProviderProps> = ({children}) => {
  const entityServiceClient = useEntityServiceClient();

  const {user} = useUser();
  const subdomain = useSubdomain();
  const organizationRoomId = user.org_id ? `${subdomain}:${user.org_id}` : null;

  return (
    <LiveblocksProvider
      authEndpoint={async () => {
        const url = new URL('/api/liveblocks/auth', process.env.WEB_BASE_URL);

        if (subdomain) {
          url.host = `${subdomain}.${url.host}`;
        }

        const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        return response.json();
      }}
      resolveMentionSuggestions={async ({text}) => {
        const {data} = await entityServiceClient.findMany('organization-members', {
          filters: {
            name: {
              $containsi: text,
            },
            isTetraGuest: {
              $eq: false,
            },
          },
          fields: ['uid'],
        });

        const uids = data
          .map((member) => {
            return member.attributes.uid;
          })
          .filter(Boolean);

        return uids;
      }}
      resolveUsers={async ({userIds}) => {
        const {data} = await entityServiceClient.findMany('organization-members', {
          filters: {
            uid: {
              $in: userIds,
            },
          },
          fields: ['uid', 'name', 'avatar'],
        });

        const map = new Map<string, {name: string; avatar: string}>();
        for (const member of data) {
          if (!map.has(member.attributes.uid)) {
            map.set(member.attributes.uid, {
              name: member.attributes.name,
              avatar: member.attributes.avatar,
            });
          }
        }

        return userIds.map((userId) => {
          return map.get(userId);
        });
      }}
    >
      {organizationRoomId ? (
        <RoomProvider id={organizationRoomId} initialPresence={{}}>
          {children}
        </RoomProvider>
      ) : (
        children
      )}
    </LiveblocksProvider>
  );
};

export default LiveblocksRealtimeProvider;
