import {useContext} from 'react';

import SessionContext from '@src/contexts/session';
import {AppAbilities} from '@src/library/permissions';

const usePermission: AppAbilities['can'] = (...args) => {
  const {permissions} = useContext(SessionContext);
  const allowed = permissions?.can(...args);
  return allowed!;
};

export default usePermission;
