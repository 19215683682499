import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/onstage/web/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/onstage/web/src/app/(main)/account-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/onstage/web/src/app/(main)/organization-logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/onstage/web/src/app/(main)/support-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/onstage/web/src/app/(main)/top-navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/onstage/web/src/app/(main)/universal-search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationToast"] */ "/vercel/path0/onstage/web/src/components/alerts/notifications.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/onstage/web/src/components/posthogTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/onstage/web/src/components/providers/liveblocks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/onstage/web/src/components/providers/session.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/onstage/web/src/components/providers/tetra-bot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/onstage/web/src/components/providers/uppy.tsx");
