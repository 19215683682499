'use client';

import {ReactNode, useEffect, useState} from 'react';

import {createMongoAbility} from '@casl/ability';

import SessionContext from '@src/contexts/session';
import {AppAbilities} from '@src/library/permissions';
import {Session} from '@src/library/workos/get-workos-session';
import TetraDots from '../TetraDots';
import Backdrop from '@mui/material/Backdrop';

interface Props {
  children: ReactNode;
}

export default function SessionProvider({children}: Props) {
  const [sessionData, setSesisonData] = useState<{
    session: Session | null;
    isLoading: boolean;
  }>({
    session: null,
    isLoading: true,
  });
  const {session, isLoading} = sessionData;

  useEffect(() => {
    const getSesison = async () => {
      const response = await fetch('/api/workos/me', {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        return null;
      }

      const session = await response.json();
      setSesisonData({
        session: session as Session,
        isLoading: false,
      });
    };

    void getSesison();
  }, []);

  useEffect(() => {
    if (!isLoading && !session?.user) {
      window.location.href = `/api/workos/login?returnTo=${window.location.href}`;
    }
  }, [isLoading, session?.user]);

  return (
    <SessionContext.Provider
      value={{
        organization: session?.organization,
        permissions: createMongoAbility<AppAbilities>(session?.permissions ?? []),
        user: session?.user,
        subdomain: session?.subdomain,
      }}
    >
      <Backdrop open={!session} sx={{zIndex: 10000}}>
        <TetraDots spin width={64} height={64} />
      </Backdrop>
      {children}
    </SessionContext.Provider>
  );
}
